import { humanDate } from "helpers/Utils/formatters";
import { apiRatesResponse, dataPointDto } from "modules/Rates/Models";




export class BalticRateDTO {
    id: string;
    code: string;
    description: string;
    group: string
    precision: number;
    startDate: string;
    endDate: string;
    private values: dataPointDto[];
    unit: string;

    constructor(data: apiRatesResponse) {
        this.unit = data.unit;
        this.endDate = data.endDate;
        this.id = data.id;
        this.code = data.code;
        this.description = data.description;
        this.group = data.group;
        this.precision = data.precision;
        this.startDate = data.startDate;
        this.values = data.values;
    }

    get currentValue(): string { 
        return this.currencyFormatter.format(this.values[0].value);
    }

    get currentValueRaw(): number { return this.values[0].value }

    get currentPriceTime(): string {
        return humanDate(this.values[0].date, {time: true});
    }

    get decimalPlaces(): number {
        return this.precision ? this.precision : 2;
    }

    private currencyFormatter = new Intl.NumberFormat(
        "en-US",
        {
            style: "decimal",
            minimumFractionDigits: this.decimalPlaces 
        }
    )
} 

export class BalticRatesResponse {

    public results: BalticRateDTO[] = [];

}
